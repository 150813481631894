import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 12; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/2-nov-1/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function WildAboutAnimals2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Class I Presentation - Wild About Animals</h1>

        <p>
          The little learners of class I of Chinmaya Vidyalaya, New Delhi hosted
          their Class Presentation, Wild About Animals, on 18th October 2023
          with great zeal and excitement. The occasion was graced by Shri Atul
          Karanjakar, the Zonal Director, CCMT Education Cell.
          <br />
          <br />
          The programme included a melodious rendition of ‘Rang Birangi Dharti,
          a skit on the significance of teamwork and an energetic and
          scintillating dance performance that enthralled everyone.
          <br />
          <br />
          Addressing the audience, Ms. Archana Gaur, the Primary Headmistress,
          offered valuable insights on conserving nature and its wildlife. The
          Principal, Ms. Archana Soni, applauded the students for their efforts
          and shed light on the role of the Vidyalaya in developing resilient
          and happy individuals.
          <br />
          <br />
          The presentation culminated with a message that saving wildlife is
          more than just an expression of compassion; it is a legacy we bequeath
          to future generations.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
